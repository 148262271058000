.listBlock {
  list-style: none;
  margin: 30px 10px 0;
  padding: 0;
  & > li {
    margin: 0 0 10px;
    display: flex;
    & > span.label {
      width: 20%;
      display: inline-block;
    }
  }
}

.badgesRow {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 100px 5px 5px;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  background: #c0c0c0;
  position: relative;
  min-height: 70px;
  align-items: flex-start;
  width: 80%;
  .item {
    border: 1px solid #3a3a3a;
    position: relative;
    background: #545454;
    color: #dedede;
    border-radius: 3px;
    margin-right: 5px;
    padding: 4px 25px 4px 4px;
    .close {
      position: absolute;
      top: 0;
      right: 3px;
      cursor: pointer;
      width: 15px;
      height: 100%;
      &:before, &:after {
        content: "";
        position: absolute;
        background: #252525;
        top:50%;
        left: 0;
        width: 100%;
        height: 1px;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  .autocomplete {
    position: absolute;
    top:5px;
    right: 5px;
    background: #fff;
    border-radius: 3px;
  }
}

