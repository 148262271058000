
.block {
  display: flex;
  justify-content: space-around;
}

.title {
  font-weight: bold;
}

.itemBlock {
  display: flex;
}

.check {
  display: flex;
  span {
    display: inline-block;
    width: 20px;
    margin-left: 5px;
  }
}
.itemBlockP > div{
  display: flex;
  vertical-align:center;
  margin-left: 5px;
  padding: 10px;
  position: relative;
  &> .separ {
    width: 1px;
    height: 245px;
    background: #231f20;
    margin: 0 10px;
  }
}
.weatherPrice {
  width: 150px;
  height: 35px;
  padding: 5px;
  text-align: center;
  &.plus {
    background: green;
  }

  &.minus {
    background: red;
  }
}

.weatherPriceBlock {
  width: 300px;
  height: 35px;
  display: flex;
  margin-right: 10px;
  & > div {
    padding: 5px;
  }
  .plus {
    background: green;
  }
  .minus {
    background: red;
  }
}

.blockConf {
  display: flex;
  flex-direction: column;
  .blockConfBlock {
    display: block;
    margin-bottom: 10px;
  }
}