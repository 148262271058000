.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.bg {
  background: #231F20 0 0 no-repeat padding-box;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canarioModalImg {
  position: absolute;
  top: 53px;
  left: calc(100% - 130px);
  @media screen and (max-width: 650px) {
    display: none;
  }
}

.modalRow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 244px 0 244px 244px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  padding: 53px 172px;
  max-width: 664px;
  width: 90%;
  @media screen and (max-width: 650px) {
    padding: 30px 62px;
    border-radius: 128px 0 128px 128px;

  }

  :global .modalHeader {
    font-size: 22px;
    font-weight: 700;
    text-align: right;
    margin-bottom: 7px;
  }

  :global .contentRow {
    margin-bottom: 21px;
  }

  :global .inputBlock {
    margin-bottom: 8px;

    label {
      text-align: right;
      width: 100%;
      display: block;
    }

    input, :global .csv-reader-input {
      border-radius: 100px;
      border: 2px solid #E3E3E3;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      outline: none;
      position: relative;
    }
  }

  :global .csv-reader-input input {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    opacity: 0;
  }

  :global .modalFooter {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 24px;
    @media screen and (max-width: 650px) {
      grid-template-columns: 45% 45%;
      grid-column-gap: 10%;
    }
  }

  :global .buttonBlock {
    border-radius: 26px;
    background: #FFA800;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;

    &.small {
    }

    &.close {
      background: #fff;
      color: #231F20;
      border: 2px solid #231F20;
    }
  }
}


