.blockScroll {
  max-height: 400px;
  overflow-y: scroll;
  width: 80%;
  margin: 0 auto;
}

.charts {
  display: flex;
  h4 {
    text-align: center;
  }
}