.row {

}
.title {
  //font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.00938em;
  padding-left: 5px;
}
.datePickerRow {
  font-size: 20px;
  font-family: Rubik;
  //padding: 2px 5px 2px 0;
  height: 41px;
  border: 1px solid #C1C1C1;
  border-radius: 21px;
  color:#105198;
  padding: 0 11px;
  :global .react-datetimerange-picker__inputGroup {
    input {
      color:#105198;
    }
  }
  :global .react-datetimerange-picker__wrapper {
    border: none;
  }
  :global .react-datetimerange-picker__calendar-button {
    &:before {
      content: "";
      background: url("../../../assets/datePicker.svg");
      width: 29px;
      height: 26px;
      display: inline-block;
    }
    svg {
      display: none;

    }
  }
  :global .react-datetimerange-picker__clear-button {
    display: none;
  }
}