:global .recharts-legend-wrapper {
  bottom: -50px!important;
}
:global .recharts-brush-texts {
  font-size: 14px;
  text {
    fill: #231f20;
  }
}
.legend {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style: none;
  margin: 0;
  font-size: 12px;

  li {
    margin: 0 5px;
    padding: 5px;
    color: white;
    border-radius: 4px;
    &.noactive {
      background: #b70000 !important;
    }
  }
}

.customTooltip {
  background: #fff;
  font-size: 12px;
  color: #565656;
  border: 1px solid #3a3a3a;
  .label {
    font-size: 13px;
    color: #212121;
    border-bottom: 1px solid #3a3a3a;
    padding: 5px 3px;
    font-weight: bold;
  }
  .body {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      padding: 5px 3px;
      border-bottom: 1px solid #6d6d6d;
      label {
        color: #3f3f3f;
      }
      span {
        margin-left: 5px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.rowChart {
  display: flex;
  justify-content: center;
}