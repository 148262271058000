.container {
  min-height: 100vh;
  //min-height: 500px;
  height: 100%;
  position: relative;
  @media screen and (max-height: 500px) {
    min-height: 500px;
  }
}
.blockChart {
  padding: 0 0 80px 0;
}
.bages {
  //position: absolute;
  //bottom: 0;
  //right: 0;
  //z-index: 1;
}