.hideLink {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.filterButton {
  font-size: 20px;
  font-weight: 500;
  border: 2px solid #231F20;
  border-radius: 26px;
  height: 40px;
  line-height: 38px;
  padding: 0 26px;
  cursor: pointer;
  align-items: center;
  position: relative;

  &.download {
    padding-right: 40px;
  }

  .icon {
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;
  }
}

