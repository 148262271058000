.legendRow {
  display: flex;
  .legendItem {
    cursor: pointer;
    padding: 4px 6px;
    font-size: 14px;
    background: #b70000;
    margin: 0 5px;
    color: #fff;
    border-radius: 4px;
    &.active {
      background: #105198;
    }
  }
}

h3 {
  color: red;
}

.buttons {
  display: flex;
  margin: 10px 0;
  > * {
    margin: 0 5px!important;
  }
}